// React Library
import React, {useState} from "react";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Powered_by_Lockup from "../assets/images/Powered_by_Lockup.png";
import BAA from "../components/FooterLinks/BAA";
import PrivacyPolicy from "../components/FooterLinks/PrivacyPolicy";
import TermsAndCondition from "../components/FooterLinks/TermsAndCondition";
import {BUSINESS_ASSOCIATION_AGREEMENT, PRIVACY_POLICY, TERMS_OF_USE} from "../utilities/constants";
import PopupBox from "./PopupBox";

export const Footer = () => {
    const [popupDetails, setPopupDetails] = useState({
        open: false,
        heading: "",
    });

    const handleClickOpen = (link: string) => {
        setPopupDetails({...popupDetails, open: true, heading: link});
    };

    const handleClose = () => {
        setPopupDetails({...popupDetails, open: false, heading: ""});
    };

    if (popupDetails.open)
        return (
            <PopupBox handleClose={handleClose} heading={popupDetails.heading}>
                {popupDetails.heading === TERMS_OF_USE && <TermsAndCondition/>}
                {popupDetails.heading === PRIVACY_POLICY && <PrivacyPolicy popup={true}/>}
                {popupDetails.heading === BUSINESS_ASSOCIATION_AGREEMENT && <BAA/>}
            </PopupBox>
        );

    return (
        <div className="footer">
            <div className="footer__image">
                <img
                    src={Powered_by_Lockup}
                    alt="Powered By Lockup"
                />
            </div>
            <div className="footer__privacy-text">
                <p>
                    For information about the privacy of your data, please&nbsp;
                    <a
                        href="https://servier.com/en/data-privacy/"
                        target="_blank"
                    >
                        click here
                    </a>
                    .
                    <br/>
                </p>
                <p className="mb-0">
                    ©2025 Servier Pharmaceuticals LLC. Boston, MA 02210. Customer
                    Service: 1-800-807-6124. Servier and the Servier logo are registered trademarks of LES LABORATOIRES SERVIER. 
                    All other trademarks, registered or unregistered, are the property of their respective owners. US-PAT-00120 02/25
                </p>
            </div>
            <div className="footer__policy_link">

                <p
                    className="login_hyperlink policy-links"
                    onClick={() => handleClickOpen(TERMS_OF_USE)}
                >
                    Terms of Use
                </p>

                <p
                    className="login_hyperlink policy-links"
                    onClick={() => handleClickOpen(PRIVACY_POLICY)}
                >
                    Privacy Policy
                </p>

                <p
                    className="login_hyperlink policy-links"
                    onClick={() =>
                        handleClickOpen(BUSINESS_ASSOCIATION_AGREEMENT)
                    }
                >
                    BAA
                </p>
            </div>
        </div>
    );
};
