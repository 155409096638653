import {ViewRendererInterface} from "./Interfaces/Interfaces";
import {Footer} from "../templates/Footer";
import {Link} from "react-router-dom";
import logo from "../assets/images/ServierONE_RBG_600x315.png";
import {RegistrationBreadcrumb} from "./RegistrationBreadcrumb";
import React from "react";
import { ROUTE_PATHS } from "../utilities/constants";

export const ViewRenderer = (props: ViewRendererInterface) => {
    const {bodyComponent, navComponent} = props;

    return <div className='layout'>
        <div className='layout__wrapper'>
            <div className='layout__body offset-1 col-6'>
                {bodyComponent}
            </div>
            <div className='layout__nav offset-1 col-4'>
                <div className='layout__logo'>
                    <Link to={ROUTE_PATHS.eligibility}><img src={logo} alt="logo"/></Link>
                </div>
                <div className='layout__content'>
                    {navComponent}
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
}