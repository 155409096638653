import {formatToArray} from "./utilities";

const statesData = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
};

const suffixData = {
    jr: 'Jr',
    sr: 'Sr',
    i: 'I',
    ii: 'II',
    iii: 'III',
    iv: 'IV',
    v: 'V',
    md: 'MD',
    dds: 'DDS',
    phd: 'PhD',
    dvm: 'DVM'
};

const vials = {
    1: '1 Vial',
    2: '2 Vials',
    3: '3 Vials',
    4: '4 Vials',
    5: '5 Vials',
    6: '6 Vials',
    7: '7 Vials',
    8: '8 Vials',
    9: '9 Vials',
    10: '10 Vials',
    11: '11 Vials',
    12: '12 Vials',
    13: '13 Vials',
    14: '14 Vials'
};



export const states = formatToArray(statesData);
export const suffix = formatToArray(suffixData);
export const vialOptions = formatToArray(vials);

